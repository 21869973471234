/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.Loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Car__body {
    -webkit-animation: shake1 0.2s ease-in-out infinite alternate;
    animation: shake1 0.2s ease-in-out infinite alternate;
}

.Car__line {
    transform-origin: center right;
    stroke-dasharray: 22;
    -webkit-animation: line 0.8s ease-in-out infinite;
    animation: line 0.8s ease-in-out infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.Car__line--top {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.Car__line--middle {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.Car__line--bottom {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

@-webkit-keyframes shake1 {
    0% {
        transform: translateY(-1%);
    }

    100% {
        transform: translateY(3%);
    }
}

@keyframes shake1 {
    0% {
        transform: translateY(-1%);
    } 

    100% {
        transform: translateY(3%);
    }
}

@-webkit-keyframes line {
    0% {
        stroke-dashoffset: 22;
    }

    25% {
        stroke-dashoffset: 22;
    }

    50% {
        stroke-dashoffset: 0;
    }

    51% {
        stroke-dashoffset: 0;
    }

    80% {
        stroke-dashoffset: -22;
    }

    100% {
        stroke-dashoffset: -22;
    }
}

@keyframes line {
    0% {
        stroke-dashoffset: 22;
    }

    25% {
        stroke-dashoffset: 22;
    }

    50% {
        stroke-dashoffset: 0;
    }

    51% {
        stroke-dashoffset: 0;
    }

    80% {
        stroke-dashoffset: -22;
    }

    100% {
        stroke-dashoffset: -22;
    }
}
