/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --header-logo-width: 194px;
    --header-logo-height: 24px;

    --header-height: 65px;
    --header-nav-height: 65px;
    --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top));

    @include desktop {
        --header-top-menu-height: 32px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--menu-total-height));
    }

    --theme-color: #10335B;
    --font-color: #0a0903; //#0a0903
    --black-color: #000000;
    --border-color: #e2e2e2;
    --white-color: #ffffff;
    --body-color: #f4f4f4;
    --sidebar-dark-color: #D9D9D9;
    --sidebar-light-color: #E6E6E6;

    --font-gotham: "Gotham",sans-serif;
}

@mixin button-invisible {
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    width: 0;
    height: 0;
}

@mixin button-visible {
    opacity: 1;
    height: 25px;
    width: 25px;
    pointer-events: all;
    overflow: visible;
    cursor: pointer;
}

@mixin visible-on-mobile {
    @include button-visible;

    @include desktop {
        @include button-invisible;
    }
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
}

body#html-body {
    background-color: var(--body-color);
    font-family: var(--font-gotham);
    color: var(--font-color);
}

p {
    color: var(--font-color);
}

.Header {
    align-items: center;
    background: var(--body-color);
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-block-start: env(safe-area-inset-top, 0);
    width: 100%;
    position: fixed;
    z-index: 100;
    inset-block-start: var(--demo-notice-height);

    > * {
        max-width: var(--content-wrapper-width);
        width: 100%;
    }

    &_menu,
    &_menu_subcategory {
        .Header-Button_type {
            &_close {
                @include desktop {
                    @include button-visible;
                }
            }

            &_menu {
                @include desktop {
                    @include button-invisible;
                }
            }
        }
    }

    &_name {
        &_menu {
            border-block-end: none;
        }

        &_search,
        &_menu_subcategory {
            border-block-end: 1px solid var(--primary-divider-color);

            @include mobile {
                border-block-end: none;
            }
        }

        &_category {
            position: fixed;

            @include mobile {
                border-block-end: none;
            }
        }
    }

    &-Button {
        @include button-invisible;

        &_isVisible {
            @include mobile {
                @include button-visible;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: .1;
        }

        &_type {
            &_logout,
            &_setting,
            &_menu,
            &_minicart {
                @include button-visible;

                @include desktop {
                    height: 24px;
                    margin-inline-start: 35px;
                }
            }

            &_logout,
            &_setting {
                color: var(--color-white);

                &:hover {
                    color: var(--color-white);
                }
            }

            &_account {
                @include button-visible;
            }

            &_home {
                @include visible-on-mobile;
            }

            &_minicart {
                @include desktop {
                    margin-inline-start: auto;

                    .CartIcon {
                        fill: var(--color-white);
                    }
                }
            }

            &_back {
                .ChevronIcon {
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                }
            }

            &_close {
                .CloseIcon {
                    width: 27px;
                    height: 27px;
                }
            }

            &_share {
                @include button-visible;
            }

            &_compare {
                @include button-visible;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }

    &-IconsWrapper {
        display: flex;
        color: var(--color-white);
        // justify-content: flex-end;
        column-gap: 20px;
        // flex: 1;
        order: 3;

        .Header-Button_type_minicart {
            margin-inline-start: 0;
        }

        .Header-LogoutButtonWrapper {
            button {
                margin-inline-start: 0;
            }
        }
    }

    &-MinicartButtonWrapper {
        cursor: pointer;
        height: 100%;
        width: 100%;
    }

    &-CompareButtonWrapper {
        margin-inline-start: 35px;
        height: 24px;

        a {
            all: initial;
        }
    }

    &-CompareCount {
        background: #0A0903;
        border-radius: 8px;
        color: var(--color-white);
        height: 17px;
        padding: 2px;
        position: absolute;
        min-width: 17px;
        display: flex;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-family: "Muli", sans-serif;

        @include mobile {
            inset-block-start: -7px;
            inset-inline-end: -11px;
        }

        @include desktop {
            inset-block-start: -10px;
            inset-inline-end: -12px;
        }
    }

    &-LogoWrapper {
        inset-block-end: 0;
        height: var(--header-logo-height);
        inset-inline-start: 0;
        max-width: 0;
        opacity: 0;
        overflow: hidden;
        inset-inline-end: 0;
        inset-block-start: 0;
        width: var(--header-logo-width);
        padding: 10px 0;
        flex: 0 0 250px;

        @include mobile {
            margin: auto;
        }

        @include desktop {
            grid-column: 1 / 2;
            min-height: var(--header-nav-height);
            // position: absolute;

            @include logo-visible;
        }

        &_isVisible {
            @include logo-visible;
        }
    }

    &-Minicart {
        &ItemCount {
            background: var(--primary-base-color);
            border-radius: 8px;
            color: var(--color-white);
            height: 17px;
            padding: 2px;
            position: absolute;
            min-width: 17px;
            display: flex;
            font-size: 11px;
            justify-content: center;
            align-items: center;
            font-weight: 700;

            @include mobile {
                inset-block-start: -7px;
                inset-inline-end: -11px;
            }

            @include desktop {
                inset-block-start: -10px;
                inset-inline-end: -12px;
            }
        }
    }

    &-Nav {
        align-items: center;
        display: flex;
        height: var(--header-nav-height);
        margin: auto;
        max-width: 2020px;
        // max-width: var(--content-wrapper-width);
        padding: 0 8px;
        column-gap: 0;

        @include desktop {
            // display: grid;
            // grid-template-columns: minmax(var(--header-logo-width), 1fr) minmax(auto, 1020px) 1fr;
            justify-content: space-between;
            // padding-inline: 30px;
        }
    }

    &-MyAccount {
        &Wrapper {
            .UserIcon {
                fill: var(--color-white);
            }
        }
    }

    &-News {
        align-items: center;
        display: flex;

        &Button {
            font-size: 12px;
            margin-inline-start: 5px;
        }

        .ExclamationMarkIcon {
            inset-block-end: 1px;
            height: 16px;
            margin-inline-end: 10px;
            width: 16px;
        }
    }

    &-Title {
        color: var(--header-color);
        margin: auto;
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        inset-inline-end: 0;
        text-align: center;
        text-overflow: ellipsis;
        inset-block-start: -1px;
        inset-inline-start: 0;
        inset-block-end: 0;
        transition-duration: 200ms;
        transition-property: opacity;
        white-space: nowrap;
        width: 100%;
        text-transform: inherit;
        line-height: 30px;
        height: 30px;
        display: none;

        @include mobile {
            line-height: 28px;
        }

        &_isVisible {
            @include mobile {
                opacity: 1;
                max-width: calc(100% - 80px);
            }
        }
    }

    &-Welcome {
        display: none;
        font-size: 14px;
        margin-inline-start: 7px;
        text-align: end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
        align-self: center;
        line-height: 16px;
        color: var(--theme-color);

        @include wide-desktop {
            display: block;
        }
    }

    &-TopMenu {
        display: flex;
        justify-content: space-between;
        height: var(--header-top-menu-height);
        margin: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 32px;
        padding-block-start: 16px;
    }

    &-Switcher {
        align-items: center;
        display: flex;
    }

    &_isCheckout {
        .Header {
            &-Nav {
                display: flex;
            }

            &-IconsWrapper {
                display: flex;
                width: 89.4%;
            }

            &-LogoWrapper {
                position: relative;
                flex-shrink: 0;
            }

            &-Button_type_account {
                display: block;
            }

            &-Button_type_close,
            &-Button_type_back {
                @include desktop {
                    display: none;
                }
            }

            &-MyAccount {
                @include desktop {
                    height: 24px;
                }

                @include mobile {
                    position: absolute;
                    inset-inline-end: 10px;
                    width: auto;
                }
            }
        }

        .MyAccountOverlay {
            @include mobile {
                inset-block-start: var(--header-total-height);
                height: calc(100vh - var(--header-total-height));
                position: fixed;
                width: 100vw;
            }
        }
    }

    &-Wrapper {
        display: flex;
        justify-content: center;
        margin-block-end: var(--header-total-height);

        @include mobile {
            z-index: 801;
        }

        &_isPrerendered {
            @include mobile {
                .Header-TopMenu,
                .Menu-MenuWrapper {
                    display: none;
                }

                .Header-Nav {
                    & > * {
                        display: none;
                    }

                    .Header {
                        &-Title,
                        &-LogoWrapper,
                        &-Button {
                            &_isVisible {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    &_name_popup {
        @include mobile {
            z-index: 450;
        }
    }

    &-MyAccountContainer {
        display: flex;
        align-items: center;
        column-gap: 20px;

        .Header-MyAccount {
            background-color: var(--theme-color);
            width: 45px;
            height: 45px;
            border-radius: 5px;
            display: flex;
            justify-content: center;

            button svg {
                width: 35px;
                height: 25px;
            }
        }
    }

    &-LogoutButtonWrapper {
        background-color: var(--theme-color);
        width: 45px;
        height: 45px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            display: flex;

            svg {
                width: 35px;
                height: 25px;
            }
        }
    }

    .header_search_wrapper {
        display: flex;
        align-items: center;
        order: 1;
        column-gap: 10px;

        @include desktop {
            grid-column: 2 / 3;
            padding: 0;
            flex: 1;
        }

        @include mobile {
            height: 100%;
            column-gap: 10px;
        }

        > .SearchField-SearchInnerWrapper {
            > svg {
                position: absolute;
                inset-block-start: 50%;
                transform: translateY(-50%);
                z-index: 5;
                inset-inline-start: 0px;
                width: 30px;
            }
        }

        .SearchField.CarPlate {
            max-width: 200px;
            margin: 0;
            width: 100%;
            border: 0;
            border-radius: 5px;
            padding: 10px 0 10px 40px;
            height: 45px;
            font-size: 16px;
            line-height: 21px;
            text-transform: uppercase;
            font-weight: 700;
        }

        .SearchField-SearchInnerWrapper {
            // margin: 0 auto;
            // width: 100%;
            // max-width: max-content;
            order: 1;
            position: relative;
            flex: 0 0 auto;
            display: flex;
            column-gap: 10px;

            input {
                font-size: 16px;
                line-height: 21px;
                text-transform: uppercase;
                font-weight: 700;
            }
        }

        .more-info {
            order: 3;
            margin-inline-start: 30px;
            inset-inline-start: 49rem;
            width: 100%;

            @media (min-width: 1920px) {
                inset-inline-start: 49rem;
                width: 100%;
            }

            @media (min-width: 1920px) {
                p {
                    display: block;
                }
            }

            .more-info-icon {
                width: 20px;
                height: auto;

                // svg {
                //     fill: var(--white-color);
                // }
            }
        }
    }
}


/* Popup Css */

.modal-header {
    border-bottom: 0;
    padding: 1.5rem 1rem 1rem;

    .btn-close {
        position: absolute;
        inset-inline-end: 25px;
        inset-block-start: 10px;
        padding: 5px;
        font-size: 20px;
        z-index: 9;
    }
}

.modal-body {
    padding: 0;
}

.main_table_heading {
    padding: 10px 25px 20px;
    font-size: 16px;
    line-height: 20px;
}

.main_table_details {
    display: flex;
    padding-block-end: 30px;

    > div {
        flex: 1;
        text-align: center;

        .main_table_desc {
            min-height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 25px;

            span {
                font-size: 12px;
                line-height: 16px;

                &.main_table_subtitle {
                    font-weight: 700;
                    flex: 1;
                    text-align: start;
                }

                &.main_table_subvalue {
                    word-break: break-all;
                    flex: 1;
                    text-align: start;
                }
            }
        }
    }
}

section.Header-Wrapper {
    margin-block-end: 65px;
}

//  Search page Css

.searchpage-block-wrapper {
    .searchpage-content-wrapper {
        .spage-sidebar {
            max-height: calc(100vh - 130px);
            overflow-y: auto;
            position: fixed;
            z-index: 9;
            width: 100%;
            max-width: 250px;
            inset-block-start: 130px;
            padding-block-end: 50px;
            padding-block-start: 30px;

            &.active {
                padding-block-start: 95px;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            .accordion {
                .accordion-button {
                    font-size: 16px;
                    line-height: 21px;

                    &[aria-expanded="true"] {
                        background-color: var(--theme-color);
                        color: var(--white-color);
                        border: 0;
                    }
                }

                .parent {
                    &.accordion-item {
                        .accordion-button {
                            &::after {
                                content: "";
                                background: url(./down.svg) no-repeat;
                                display: block;
                                width: 14px;
                                height: 10px;
                                transform: unset;
                            }

                            &[aria-expanded="true"] {
                                background-color: var(--theme-color);
                                color: var(--white-color);
                                border: 0;

                                &::after {
                                    background: url(./white.svg) no-repeat;
                                }
                            }
                        }
                    }
                }
            }

            .sidebar-top-section {
                background: var(--white-color);
                padding: 10px;
                position: fixed;
                inset-block-start: 75px;
                width: 100%;
                max-width: 250px;
                z-index: 9;

                p.car-plate-detail {
                    span {
                        display: block;

                        &.title {
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 400;
                        }

                        &.modal-no {
                            font-size: 18px;
                            line-height: 22px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        .spage-product-content {
            padding-inline-start: 255px;
            margin-block-end: 25px;
            // padding-inline-end: 370px;
            padding-block-start: 10px;

            .No-product {
                flex-wrap: wrap;
                padding-block-start: 50px;

                .title {
                    font-size: 24px;
                    line-height: 28px;
                    font-weight: 700;
                    color: var(--black-color);
                    margin-block-end: 14px;
                }

                .search-section {
                    width: 100%;
                    max-width: 1070px;
                    margin-block-end: 28px;

                    .SearchField-SearchInnerWrapper {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        > svg {
                            width: 65px;
                            height: 80px;
                        }

                        .SearchField {
                            max-width: 100%;
                            min-height: 80px;
                            font-size: 36px;
                            line-height: 44px;
                            font-weight: 700;
                            color: #9B9B9B;
                            text-align: center;
                            text-transform: uppercase;
                            border-color: transparent;
                        }

                        .SearchField-SearchIcon,
                        .SearchField-CloseIcon {
                            width: 86px;
                            height: 80px;
                            justify-content: center;

                            svg {
                                height: 45px;
                            }
                        }
                    }
                }

                .slider-section {
                    width: 100%;
                    max-width: 1070px;

                    .carousel {
                        .carousel-indicators {
                            inset-block-end: -60px;

                            button {
                                background-color: #ACACAC;
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                            }
                        }

                        a {
                            width: 50px;
                            height: 50px;
                            inset-block-start: 50%;
                            transform: translateY(-50%);
                            background: var(--white-color);
                            color: var(--black-color);
                            border-radius: 50%;

                            &.carousel-control-next {
                                inset-inline-end: 20px;
                            }

                            &.carousel-control-prev {
                                inset-inline-start: 20px;
                            }
                        }
                    }
                }
            }

            .sproduct-list {
                .sproduct-item {
                    // justify-content: center;

                    > div {
                        justify-content: center;
                        max-width: 100%;
                        // border-left: 0;
                        // border-right: 0;
                    }

                    .sproduct-picture {
                        flex: 0 0 10%;
                        border-left: 1px solid #cfcfcf;
                    }

                    .sproduct-name {
                        flex: 0 0 20%;
                        display: flex;
                        flex-direction: column;
                        word-break: break-all;
                        align-items: flex-start;

                        p.sproduct-link {
                            font-size: 16px;
                            line-height: 21px;
                            text-align: start;
                            margin-block-end: 0;

                            &.name-link {
                                font-size: 16px;
                                line-height: 22px;
                                font-weight: 400;
                                text-align: start;

                                svg {
                                    padding-inline-start: 5px;
                                    font-size: 22px;
                                    padding-block-end: 3px;
                                }
                            }
                        }

                        a {
                            text-decoration: none;
                            color: var(--black-color);
                        }
                    }

                    .sproduct-header-details {
                        display: flex;
                        width: 100%;
                    }

                    .sproduct-body-details {
                        flex: 1;
                        border-top: 1px solid #cfcfcf;
                        border-right: 1px solid #cfcfcf;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-end;

                        .sproduct-item .spbody-column {
                            // border-top: 0;
                            margin-block-start: -1px;
                        }

                        .disable_button {
                            padding: 10px 20px 25px;

                            button {
                                font-size: 14px;
                                line-height: 19px;
                            }
                        }
                    }

                    .sproduct-details {
                        display: flex;
                        flex: 1;
                    }

                    .spheader-column {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .sproduct-brand {
                        flex: 0 0 18%;
                        justify-content: space-around;
                        max-width: 100%;
                        padding: 6px;

                        p.sproduct-brandlabel {
                            color: var(--theme-color);
                            background: transparent;
                            font-size: 16px;
                            line-height: 20px;
                            text-align: center;
                            word-break: break-all;
                        }
                    }

                    .sproduct-price {
                        flex: 0 0 15%;
                        padding: 6px 0px;
                        max-width: 100%;
                        flex-wrap: wrap;
                        flex-direction: column;

                        p {
                            margin: 0 auto;
                            font-size: 16px;
                            line-height: 21px;

                            &.sproduct-refundable {
                                width: 100%;
                                font-weight: bold;
                                color: var(--theme-color);
                            }
                        }
                    }

                    .sproduct-artnr {
                        flex: 0 0 25%;
                        max-width: 100%;
                        word-break: break-all;
                        text-align: center;

                        p {
                            font-size: 16px;
                            line-height: 21px;
                            margin-block-end: 0;
                        }
                    }

                    .sproduct-stock {
                        flex: 0 0 20%;
                        max-width: 100%;
                        flex-direction: column;
                        word-break: break-all;

                        p {
                            margin: 0;
                            font-size: 16px;
                            line-height: 21px;
                        }
                    }

                    .sproduct-order {
                        flex: 0 0 22%;
                        max-width: 100%;

                        h6 {
                            margin-block-end: 0;
                        }

                        .wrapper {
                            width: 100%;
                            max-width: 120px;
                            height: 35px;

                            button {
                                padding: 0;
                                width: 35px;
                                height: 35px;
                                background: var(--theme-color);
                                color: var(--white-color);
                                border-radius: 5px;
                            }

                            input {
                                max-width: 35px;
                                min-width: 35px;
                                height: 35px;
                                background: #E3E3E3;
                            }
                        }
                    }

                    &:nth-child(even) {
                        background-color: transparent;
                    }

                    &:nth-child(odd) {
                        background-color: transparent;
                    }
                }

                &.content-section {
                    .sproduct-body {
                        &:nth-child(odd) {
                            background-color: var(--white-color);
                        }

                        &:nth-child(even) {
                            background-color: var(--white-color);
                        }

                        .sproduct-body-details {
                            .sproduct-body {
                                &:nth-child(odd) {
                                    background-color: transparent;
                                    // border-top: 1px solid #cfcfcf;
                                    border-bottom: 0;
                                    width: 100%;
                                }

                                &:nth-child(even) {
                                    background-color: transparent;
                                    border-bottom: 0;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .sproduct-list.title-section {
                background: var(--theme-color);
                position: sticky;
                inset-block-start: 65px;
                z-index: 9;

                .sproduct-item .sproduct-price p {
                    flex: 1;
                    max-width: 100%;
                    padding: 6px 0;
                    font-size: 16px;
                }

                .spheader-row .spheader-column h6 {
                    color: var(--white-color);
                }
            }

            ul.pagination {
                justify-content: center;
                padding: 20px 0;
            }
        }

        .spage-cart-section {
            width: 100%;
            max-width: 20%;
            margin-block-start: 10px;
            position: sticky;
            inset-inline-end: 0;
            font-size: 12px;
            z-index: 10;
            inset-block-start: 70px;
            max-height: calc(100vh - 70px);
            overflow-y: auto;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                display: none;
            }

            .sticky-section + .add-cart-button {
                inset-block-end: 0;
            }

            .add-cart-button {
                // position: fixed;
                inset-block-end: 100px;
                padding: 20px 20px;
                max-width: 400px;
                position: sticky;
                background: var(--theme-color);
            }

            // .btn-success {
            //     background-color: var(--theme-color);
            // }

            > p {
                padding: 20px;
                font-size: 26px;
                line-height: 30px;
                text-transform: capitalize;
                color: var(--theme-color);
            }

            .emptyCart-header,
            .emptyCart-description {
                padding: 0;
                font-size: 16px;
                line-height: 21px;
                text-align: center;
                margin: 1rem 0;
            }

            .emptyCart-header {
                font-size: 18px;
                line-height: 23px;
                font-weight: 700;
            }

            table {
                tr {
                    td {
                        .wrapper {
                            width: 100%;
                            padding: 5px;

                            button {
                                padding: 0;
                            }

                            input {
                                min-width: 20px;
                            }
                        }
                    }
                }
            }

            .cart-section-main {
                font-size: 14px;
                // padding-block-end: 100px;

                .items {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #ccc;
                    margin: 0 10px;

                    .items-data {
                        flex: 0 0 60%;
                    }

                    .sku {
                        flex: 0 0 20%;
                        word-break: break-all;
                        font-size: 16px;
                        line-height: 21px;
                        font-weight: 700;
                        text-decoration: underline;
                        color: var(--theme-color);
                        margin-block-end: 5px;

                        @media (min-width: 1920px) {
                            flex: 0 0 25%;
                        }
                    }

                    .name {
                        flex: 0 0 35%;
                        word-break: break-all;
                        font-size: 14px;
                        line-height: 19px;
                        font-weight: 400;
                        margin-block-end: 5px;

                        @media (min-width: 1920px) {
                            flex: 0 0 30%;
                        }
                    }

                    .price {
                        flex: 0 0 20%;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 700;
                    }

                    .qty {
                        flex: 1;

                        h6 {
                            margin-block-end: 0;
                        }

                        .wrapper {
                            width: 100%;
                            // padding: 5px;
                            max-width: 105px;
                            margin: 0 auto;
                            height: 35px;

                            button {
                                padding: 0;
                                width: 35px;
                                height: 35px;
                                background: var(--theme-color);
                                color: var(--white-color);
                                border-radius: 5px;
                            }

                            input {
                                min-width: 35px;
                                max-width: 35px;
                                height: 35px;
                                background: #E3E3E3;
                            }
                        }
                    }

                    &> div {
                        padding: 10px 5px;
                    }
                }
            }
        }

        .card {
            .card-header {
                .card-header {
                    h2 {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }

            .card-innerbody {
                padding: 0 15px;
            }
        }
    }
}

// Qty Box

.product-section {
    td {
        .wrapper {
            width: 100%;
            padding: 5px;

            button {
                padding: 0;
            }

            input {
                min-width: 20px;
            }
        }
    }
}

.serachPage .pagination li a.page-link {
    color: var(--theme-color);
    font-size: 15px;
    padding: 10px 15px;
}

.serachPage .pagination li.active>.page-link {
    background-color: var(--theme-color);
    color: var(--white-color);
    border-color: var(--theme-color);
}

.Router-MainItems {
    .HomePage {
        min-height: calc(100vh - 140px);
    }

    main.CategoryPage {
        ul.ProductListPage {
            .row {
                align-items: flex-start;

                .col {
                    table {
                        thead {
                            background: var(--theme-color);
                            color: var(--white-color);
                            border: 1px solid #cfcfcf;

                            th {
                                height: 42px;
                                vertical-align: middle;
                                text-align: center;
                                font-size: 16px;
                                line-height: 21px;

                                &.pricetitle span {
                                    background: var(--theme-color);
                                    border-radius: 2px;
                                    font-weight: 700;
                                    color: var(--white-color);
                                    padding: 6px;
                                    width: 100%;
                                    max-width: 102px;
                                    display: block;
                                    margin: 0 auto;
                                }
                            }
                        }

                        tbody {
                            border: 1px solid #cfcfcf;
                        }


                        tr td {
                            text-align: center;
                            vertical-align: middle;
                            border: 0;
                            font-size: 18px;
                            line-height: 22px;

                            &.brandlabel span {
                                color: var(--theme-color);
                                background: transparent;
                                font-size: 16px;
                                font-weight: bold;
                            }

                            .wrapper {
                                margin: 0 auto;
                            }

                            p {
                                margin-block-end: 0;
                            }

                            &.searchprice {
                                font-weight: bold;
                                color: var(--black-color);
                            }

                            &.qty {
                                flex: 0 0 25%;

                                .wrapper {
                                    width: 100%;
                                    max-width: 70px;
                                    min-width: 120px;

                                    button {
                                        padding: 0;
                                        width: 40px;
                                        height: 40px;
                                        background: var(--theme-color);
                                        color: var(--white-color);
                                        border-radius: 5px;
                                    }

                                    input {
                                        min-width: 20px;
                                        max-width: 40px;
                                        height: 40px;
                                        background: #E3E3E3;
                                    }
                                }
                            }
                        }
                    }
                }

                .product-section.col {
                    position: sticky;
                    inset-block-start: 82px;
                    max-height: 850px;
                    overflow-y: auto;

                    > div {
                        // position: fixed;
                        width: 100%;
                        max-width: 431px;

                        .cart-section-main {
                            .items {
                                display: flex;
                                align-items: center;
                                border-bottom: 1px solid #ccc;
                                font-size: 14px;

                                .items-data {
                                    .sku {
                                        font-size: 18px;
                                        line-height: 22px;
                                        font-weight: 700;
                                        text-decoration: underline;
                                        color: var(--theme-color);
                                        margin-block-end: 5px;
                                    }

                                    .name {
                                        font-size: 16px;
                                        line-height: 20px;
                                        font-weight: 400;
                                        margin-block-end: 5px;
                                    }

                                    .price {
                                        font-size: 16px;
                                        line-height: 20px;
                                        font-weight: 700;
                                    }
                                }

                                > div {
                                    padding: 10px 5px;
                                    flex: 1;
                                    // word-break: break-all;
                                }

                                .name {
                                    word-break: break-all;
                                }

                                .price {
                                    flex: 0 0 20%;
                                }

                                .qty {
                                    flex: 0 0 20%;

                                    .wrapper {
                                        width: 100%;
                                        max-width: 70px;
                                        min-width: 120px;

                                        button {
                                            padding: 0;
                                            width: 40px;
                                            height: 40px;
                                            background: var(--theme-color);
                                            color: var(--white-color);
                                            border-radius: 5px;
                                        }

                                        input {
                                            min-width: 20px;
                                            max-width: 40px;
                                            height: 40px;
                                            background: #E3E3E3;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .container {
        .order-section {
            display: flex;
            flex-wrap: wrap;
            column-gap: 10px;
            margin: 10px 0;
        }

        button.btn.btn-primary {
            background-color: var(--theme-color);
            border-color: var(--theme-color);
            font-size: 14px;
            line-height: 18px;
            width: 100%;
            max-width: 150px;
            min-height: 48px;
        }
    }

    .checkout-main {
        .checkout-table-section {
            flex: 1;

            .table {
                font-size: 14px;
                line-height: 18px;

                thead {
                    tr th {
                        border: 1px solid #ccc;
                        padding: 10px 5px;
                    }
                }

                tbody {
                    tr {
                        td {
                            border: 1px solid #ccc;
                            padding: 10px 5px;

                            button {
                                svg {
                                    fill: var(--theme-color);
                                    font-size: 15px;
                                    line-height: 20px;
                                }

                                &:focus,
                                &:active {
                                    box-shadow: none;
                                }
                            }
                        }

                        &:nth-child(odd) {
                            background-color: #e3e3e3;
                        }

                        &.total-content {
                            td {
                                border: 0;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            .container {
                font-size: 14px;
                line-height: 18px;
                margin: 0;
                width: 100%;
                max-width: 100%;

                .main-total {
                    justify-content: flex-end;
                    float: unset;
                }
            }
        }

        .checkout-button {
            flex: 0 0 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 10px 0;

            button {
                width: 100%;
                max-width: 150px;
                padding: 10px;
                margin: 10px;
                font-size: 14px;
                line-height: 18px;
                background: var(--theme-color);
                border-color: var(--theme-color);

                &:focus,
                &:active {
                    box-shadow: none;
                }
            }
        }
    }
}

footer.Footer {
    z-index: 11;
}

.searchpage-content-wrapper {
    background-color: transparent;

    .searchpage-block-wrapper {
        .cspage-header-block {
            // position: fixed;
            z-index: 10;
            width: 100%;
            max-width: 100%;

            .searchbar {
                width: 100%;
                max-width: 250px;
                padding: 8px 0;
                background: var(--white-color);

                .SearchField-SearchInnerWrapper {
                    position: relative;

                    input {
                        margin-inline-end: 0;
                        font-size: 16px;
                        background: #F2F2F2;
                        color: var(--theme-color);
                        border-color: transparent;
                    }

                    > div {
                        position: absolute;
                        inset-block-start: 50%;
                        inset-inline-end: 0;
                        transform: translateY(-50%);
                        background: transparent;

                        svg {
                            fill: #cccccc;
                        }
                    }
                }
            }

            .spagemoreinfo-block-wrapper {
                .spagemoreinfo-block {
                    column-gap: 10px;

                    p {
                        margin-block-end: 0;
                    }
                }
            }
        }
    }
}

.MyAccountOverlay-SignInButton,
.LoginAccount-CreateAccount,
.CreateAccount-InnerWrapper,
.ForgotPassword-InnerWrapper {
    button.Button {
        background: var(--theme-color);
        border-color: var(--theme-color);

        &:hover {
            background: var(--theme-color);
            border-color: var(--theme-color);
        }
    }
}

.MyAccount-Wrapper {
    .MyAccountCustomerTable {
        .Button_isHollow {
            color: var(--theme-color);
            border-color: var(--theme-color);
        }
    }

    .MyAccount-Heading {
        color: var(--theme-color);
    }

    .MyAccountDashboard {
        .KeyValueTable-Heading {
            background-color: var(--theme-color);
            color: var(--white-color);
        }
    }

    .ExpandableContent-Content {
        ul {
            padding-inline-start: 0;
        }
    }

    .MyAccountTabListItem_isActive {
        background-color: var(--theme-color);

        button {
            color: var(--white-color);

            svg {
                fill: var(--white-color);
            }
        }
    }
}

.MyAccountDashboard-BlockTitle,
.MyAccountDashboard-Info {
    a,
    a:hover {
        color: var(--theme-color);
    }
}

.MyAccount-Button,
.MyAccount-Button:not([disabled]):hover {
    color: var(--theme-color);
    border-color: var(--theme-color);
}


.footer_section {
    background: var(--theme-color);
    padding: 10px;
    text-align: center;
    // position: absolute;
    // inset-block-end: 0;
    z-index: 9;

    p {
        color: var(--white-color);
        margin-block-end: 0;

        a {
            color: var(--white-color);
            padding-inline-start: 5px;
        }
    }
}

.MyAccount {
    .MyAccount-TabContent_activeTab {
        &_saveorder,
        &_orderhistory {
            .SaveOrder {
                table {
                    > tbody {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;

                        > tr {
                            width: 100%;
                            // background-color: rgb(227, 227, 227);
                            font-weight: bold;
                            font-size: 17px;
                            display: flex;
                            align-items: center;
                            margin: 20px 0;
                            // border-top: 1px solid #ccc;

                            > td {
                                border: 0;
                            }

                            button {
                                background-color: var(--theme-color);
                                border-color: var(--theme-color);
                                color: var(--white-color);
                                padding: 12px 15px;
                                border-radius: 5px;
                                font-weight: bold;
                                cursor: pointer;

                                &:focus {
                                    border-color: var(--theme-color);
                                    box-shadow: none;
                                }
                            }
                        }

                        thead {
                            width: 100%;

                            > tr {
                                background-color: var(--theme-color);

                                th {
                                    background-color: transparent;
                                    color: var(--white-color);
                                    border-radius: 0;
                                    font-size: 14px;
                                }
                            }
                        }

                        tbody {
                            width: 100%;
                            border: 1px solid #ccc;

                            tr.total_price {
                                td.total {
                                    text-align: end;
                                    font-size: 16px;
                                    font-weight: bold;
                                    color: var(--theme-color);
                                }
                            }
                        }
                    }

                    td, th {
                        width: 100%;
                        vertical-align: middle;
                    }
                }
            }

            ul.pagination {
                display: flex;
                column-gap: 10px;
                justify-content: center;
                padding: 20px 0;

                li.page-item {
                    margin-block-end: 0;
                    border: 1px solid #cfcfcf;
                    margin-inline-start: -1px;
                    border-radius: 5px;

                    &::before {
                        content: unset;
                    }

                    a.page-link {
                        color: var(--theme-color);
                        font-size: 16px;
                        line-height: 19px;
                        padding: 10px 15px;
                        display: block;
                        border-radius: 5px;
                        border: 0;
                        margin-inline-start: 0;
                    }

                    &.active {
                        border-color: var(--theme-color);

                        a.page-link {
                            background-color: var(--theme-color);
                            border-color: var(--theme-color);
                            color: var(--white-color);
                            text-decoration: unset;
                        }
                    }
                }
            }
        }
    }
}

.MyAccountOverlay-ForgotPassword {
    color: var(--theme-color);

    &:hover {
        color: var(--theme-color);
    }

    &:not([disabled]):hover {
        color: var(--theme-color);
    }
}

.modal-title.h4 {
    padding-block-end: 0px;
}

.modal-header button {
    inset-block-start: 10px;
}

.product-popup-modal {
    .modal-header {
        background-color: var(--theme-color);
        color: var(--white-color);
        padding: 1rem;

        .modal-title.h4 {
            display: flex;
            align-items: flex-end;
            column-gap: 10px;

            img {
                flex: 1;
            }
        }
    }

    .modal-body {
        padding: 0;
    }
}

.product-popup-main {
    .product-name {
        font-size: 18px;
        line-height: 23px;
        padding: 10px 10px;
        font-weight: 700;
        color: var(--theme-color);
    }

    .product-data {
        display: flex;
        column-gap: 30px;
        padding: 0 10px 10px;

        .product-image {
            flex: 0 0 30%;
            display: flex;
            align-items: flex-start;

            img {
                border: 1px solid #D1CACA;
            }
        }

        .product-content {
            flex: 1;

            .product-description {
                margin-block-end: 10px;

                > div {
                    font-size: 16px;
                    line-height: 20px;
                    background: #F8F8F8;
                    border-bottom: 1px solid #D1CACA;
                    padding: 10px;
                }

                .title {
                    font-size: 30px;
                    line-height: 36px;
                    background: transparent;
                    padding: 0;
                    border-bottom: 0;
                }

                .item-tag {
                    font-size: 24px;
                    line-height: 28px;
                    background: transparent;
                    padding: 10px 0;
                }
            }

            .product-inner {
                table {
                    width: 100%;

                    thead tr > th {
                        text-align: center;
                        font-size: 14px;
                        color: var(--white-color);
                        background-color: var(--theme-color);
                        border: 1px solid var(--theme-color);
                    }

                    tbody {
                        tr {
                            border-right: 0;

                            > td {
                                padding: 10px 5px;
                                width: 100%;
                                box-sizing: border-box;
                                align-items: center;
                                font-size: 14px;
                                flex: 1;
                                min-width: 111px;
                                text-align: center;
                                border: 1px solid #ccc;
                                vertical-align: middle;

                                &.brand span {
                                    color: var(--black-color);
                                    font-size: 16px;
                                    font-weight: bold;
                                }

                                &.your-price {
                                    font-weight: 700;
                                }

                                &.price, p {
                                    font-weight: 700;
                                    color: var(--theme-color);
                                    font-size: 14px;
                                    line-height: 18px;
                                }

                                &.sku {
                                    font-weight: 700;
                                }

                                .wrapper {
                                    width: 100%;
                                    min-width: 90px;
                                    height: 30px;

                                    .minus,
                                    .plus {
                                        font-size: 10px;
                                        padding: 0;
                                        width: 30px;
                                        height: 30px;
                                        background: var(--theme-color);
                                        border-radius: 5px;

                                        svg {
                                            fill: var(--white-color);
                                        }
                                    }

                                    button {
                                        padding: 0;
                                        max-width: 30px;
                                        min-width: 30px;
                                        height: 30px;
                                        background: #E3E3E3;
                                        font-size: 15px;
                                        line-height: 20px;
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.checkout-modal-main {
    .modal-dialog {
        width: 100%;
        max-width: 1180px;

        .modal-content {
            .modal-header {
                font-size: 20px;
                line-height: 25px;
                background: var(--theme-color);
                color: var(--white-color);

                .modal-title.h4 {
                    padding-block-end: 0;
                }

                button {
                    inset-block-start: 10px;
                    color: var(--white-color);
                }
            }

            .modal-body {
                .checkout-main {
                    padding: 0;
                    font-size: 16px;

                    .order-section {
                        display: flex;
                        column-gap: 20px;
                        align-items: center;

                        button {
                            font-size: 14px;
                            padding: 5px 20px;
                        }
                    }

                    // .checkout-table-section tr {
                    //     th.customer-price {
                    //         width: 200px;
                    //     }
                    // }
                }

                .total-content {
                    td {
                        border: 0;
                        font-weight: bold;
                    }
                }
            }

            .modal-footer {
                button {
                    font-size: 14px;
                    padding: 5px 20px;
                }
            }
        }
    }
}

a.PaginationLink {
    background-color: var(--theme-color);
    border-color: var(--theme-color);
    color: var(--white-color);
    text-decoration: unset;
}

a:hover {
    color: var(--white-color);
}

.cms-page-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.super-admin-popup {
    .modal-body {
        padding: 20px;
        font-size: 14px;
        line-height: 19px;
        font-weight: bold;

        .form-control {
            font-size: 14px;
        }
    }

    .modal-footer {
        button {
            font-size: 14px;
        }
    }
}

.sticky-section {
    .add-cart-button {
        padding-block-end: 40px;
        position: sticky;
        inset-block-end: 0;
    }
}

.Field-Wrapper_type_textarea textarea {
    width: 100%;
    line-height: 1;
}

.notification.warning {
    background: #f44336;
    max-width: 320px;
    position: fixed;
    inset-inline-end: 1rem;
    inset-block-start: 7rem;
    padding: 10px;
    z-index: 9;
    border-radius: 5px;

    p {
        color: var(--white-color);
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        margin: 0;
    }
}

