/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.searchpage {
    &-content-wrapper {
        background-color: transparent;

        .cspage-header-block {
            display: flex;
            grid-column-gap: 8px;
            column-gap: 8px;

            .searchbar {
                width: 20%;
                max-width: 295px;
                padding: 8px 0;
            }

            .spagemoreinfo-block-wrapper {
                width: 100%;
                max-width: 100%;
                background: linear-gradient(89deg, #048557 1.18%, var(--theme-color) 99.51%);
                border-radius: 0px 0px 15px 0px;
                padding-block-end: 6px;

                .spagemoreinfo {
                    &-block {
                        background: #f1f1f1;
                        display: flex;
                        height: 58px;
                        border-radius: 0 0 15px 0;
                        align-items: center;
                        padding: 0 20px;
                    }

                    &-title {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        margin-block-end: 0;
                        color: var(--black-color);
                        text-transform: uppercase;
                    }

                    &-actions {
                        background: var(--theme-color);
                        border-radius: 3px;
                        color: var(--white-color);
                        padding: 5px 10px;
                        font-weight: 700;
                        font-size: 12px;
                        width: 100%;
                        max-width: 142px;
                    }
                }
            }
        }
    }

    &-block-wrapper {
        max-width: 1920px;
        padding: 0 8px 0 0;
        margin: 0 auto;

        @media (min-width: 1920px) {
            max-width: 2020px;
        }

        .searchpage-content-wrapper {
            display: flex;
            column-gap: 8px;
        }

        .spage-sidebar {
            width: 17%;
            max-width: 295px;

            .accordion {
                &-item {
                    border: 0;
                    border-radius: 0px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    border-bottom: 1px solid var(--border-color);
                    cursor: pointer;

                    .accordion-collapse {
                        // background-color: #30649f66;
                        // color: var(--white-color);

                        &.show {
                            border-radius: 0px;

                            .accordion-body {
                                padding: 15px;
                                font-size: 14px;
                                font-weight: 400;

                                div {
                                    color: var(--font-color);
                                    
                                    &.active {
                                        font-weight: bold;
                                        // padding: 10px;
                                        // border-radius: 5px;
                                        // margin-inline-start: -10px;
                                    }
                                }
                            }
                        }
                    }

                    > div {
                        &:nth-child(odd) {
                            background-color: var(--sidebar-light-color);
                        }

                        &:nth-child(even) {
                            background-color: var(--sidebar-dark-color);
                        }
                    }
                }

                &-button {
                    padding: 15px 16px;
                    // box-shadow: none !important;
                    box-shadow: none;
                    border: 0;
                    color: var(--black-color);
                    background: transparent;
                    // border-radius: 0px !important;
                    border-radius: 0px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    border-bottom: 1px solid var(--border-color);

                    &.collapsed {
                        border-bottom: 0px;
                    }

                    &.collapsed::after {
                        transform: rotate(-90deg);
                    }
                }
            }
        }

        .spage-product-content {
            width: 100%;
            max-width: 100%;

            .spheader-row {
                .spheader-column {
                    padding: 12px 10px;
                    text-align: center;

                    h6 {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;
                        margin: 0;
                        color: var(--black-color);
                    }

                    .pheader-price {
                        background: var(--theme-color);
                        border-radius: 2px;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                        margin: 0;
                        padding: 6px;
                        width: 100%;
                        max-width: 102px;
                        color: var(--white-color);
                    }

                    .pheader-brand {
                        flex: 1;
                        // text-align: left;
                        text-align: start;
                    }
                }
            }

            .spbody-row {
                .spbody {
                    &-column {
                        display: flex;
                        align-items: center;
                        padding: 5px 7px;
                    }
                }

                .sproduct {
                    &-picture {
                        padding: 0 10px;

                        .card-img-top {
                            border: 0;
                            max-height: 50px;
                            // min-height: 100px;
                            object-fit: contain;
                        }
                    }

                    &-stock,
                    &-artnr,
                    &-order {
                        justify-content: center;
                    }

                    &-price {
                        margin: 0;
                        text-align: center;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                        color: var(--theme-color);
                    }

                    &-brandlabel {
                        margin: 0;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        background: #e7e7e7;
                        border-radius: 2px;
                        padding: 2px 7px;
                        color: var(--black-color);
                    }
                }
            }

            .spbody-row .spbody-column,
            .spheader-row .spheader-column {
                border-top: 1px solid #cfcfcf;
                width: 100%;

                &:last-child {
                    border-right: 0;
                }

                &.sproduct-brand {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    column-gap: 5px;
                    padding: 6px 19px;  
                }

                .pheader-price,
                .sproduct-price {
                    flex: 0 0 55%;
                }
            }

            .sproduct {
                &-header {
                    .sproduct-item {
                        display: flex;
                    }
                }

                &-item {
                    display: flex;

                    &:last-child {
                        border-bottom: 1px solid #cfcfcf;
                    }

                    &:nth-child(odd) {
                        background-color: #f1f1f1;
                    }

                    &:nth-child(even) {
                        background-color: var(--white-color);
                    }
                }

                &-picture {
                    max-width: 108px;
                }

                &-name {
                    max-width: 188px;
                }

                &-brand {
                    max-width: 125px;
                }

                &-stock {
                    max-width: 67px;
                }

                &-artnr {
                    max-width: 80px;
                }

                &-order {
                    max-width: 160px;
                }
            }
        }
    }
}
