/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: var(--secondary-base-color);
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #000;
}

::placeholder {
    overflow: visible;
}

.SearchField {
    display: none;
    margin: 8px auto;
    max-width: 100%;
    width: 100%;
    order: 2;

    // &.CarPlate {
    //     max-width: 110px;
    // }

    @include desktop {
        display: block;
        width: 100%;
        max-width: 300px;
        grid-column: 2 / 3;
        font-size: 16px;
        margin: 0;
    }

    &_isVisible {
        display: block;

        @include mobile {
            margin-block-start: 16px;
        }
    }

    &_isActive {
        .SearchOverlay {
            &-Results {
                display: block;
            }
        }
    }

    &-CloseIcon,
    &-SearchIcon {
        position: unset;
        inset-inline-end: 10px;
        inset-block-start: calc(50% - 18px);
        width: 45px;
        height: 45px;
        background-color: var(--theme-color);
        z-index: 9;
        border-radius: 5px;
        order: 3;
        display: flex;
        align-items: center;

        svg {
            fill: #ffffff;
            width: 45px;
            height: 35px;
            padding: 5px;

            &:hover {
                fill: #ffffff;
            }
        }
    }

    &-Input {
        width: 100%;
        border: 0;
        border-radius: 5px;
        padding: 10px;
        height: 45px;
        font-size: 18px;

        &:focus {
            border: 0;
        }
    }
}
